<template>
    <div class="product">
        <a href="javascript:void(0);" class="scrollT" ref="product"></a>
        <div class="sub-banner product-banner" :style="{'backgroundImage':'url('+ proInfo.pic +')'}">
			<div class="nav wow fadeInUp">
                <div class="container">
                    <router-link
                        replace 
                        :to="`/product?id=${cateItem.id}`" 
                        :class="cateItem.id === curId ? 'active':''" 
                        v-for="cateItem in cateList" 
                        :key="cateItem.id"
                    >
                        {{cateItem.name}}
                    </router-link>
                </div>
            </div>
		</div>
        <div class="inside-nav wow fadeInDown">
			<div class="container">
				<p class="xs-show">01 Company</p>
				<div class="list">
					<a href="javascript:void(0);" @click="goAnchor(0)">01 <br/>Advantages of mods</a>
					<a href="javascript:void(0);" @click="goAnchor(1)">02 <br/>Products</a>
				</div>
			</div>
		</div>
        <div class="mainbody">
            <div class="adv-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="adv"></a>
                <div class="container">
                    <div class="top">
                        <h3 class="wow fadeInLeft">Core Advantages</h3>
                        <div class="right wow fadeInRight">
                            <div class="info">
                                {{proInfo.adv_mods}}
                            </div>
                        </div>
                    </div>
                    <div class="adv-main">
                        <div class="swiper-container swiper-adv wow fadeInLeft">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(picItem,index) in proInfo.picList" :key="index">
                                    <div class="imgBg" :style="{'backgroundImage':'url('+picItem+')'}"></div>
                                </div>
                            </div>
                            <div class="swiper-button-next"><i></i></div>
                            <div class="swiper-button-prev"><i></i></div>
                            <div class="swiper-pagination"></div>
                        </div>
                        <div class="adv-right">
                            <div class="box">
                                <div class="item wow fadeInRight" v-for="(advItem, index) in proInfo.advantList" :key="index" :data-wow-delay="(index+1)/5+'s'">
                                    <div class="name"><div class="num">{{ index + 1}}</div>{{advItem.title}}</div>
                                    <div class="txt">{{advItem.value}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pro-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="pro"></a>
                <div class="container">
                    <h3 class="wow fadeInDown">Products</h3>
                    <div class="swiper-pro wow fadeInUp">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="proItem in proList" :key="proItem.id">
                                    <div class="name">{{ proItem.title }}</div>
                                    <div class="img">
                                        <img :src="proItem.pic" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"><i></i></div>
                        <div class="swiper-button-prev"><i></i></div>
                        <div class="swiper-pagination"></div>
                    </div>
                    <div class="list wow fadeInUp">
                        <div class="item" v-for="(parItem,index) in parsList" :key="index">
                            <p>{{ parItem.title }}</p>
                            <div class="num"><span>{{ parItem.value }}</span></div>
                        </div>
                    </div>
                    <a :href="filePath.files" target="_blank" v-if="filePath !== ''" class="read-more wow fadeInUp">Read More<i></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper'
    import 'swiper/css/swiper.min.css'
    import { WOW } from 'wowjs'
    import { getProCateList, getProCateInfo, getProList } from '../../request/api'
    import _isMobile from '../../utils'
    export default {
        name:'Product',
        metaInfo:{
            title:"DMEGC Solar-product",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        props: [ 'id' ],
        data(){
            return {
                curId:'',
                proId:'',
                cateList:[],
                proInfo:{},
                proList:[],
                parsList:[],
                filePath:'',
                isMobile:false
            }
        },
        mounted(){
            this.initPro();
        },
        watch:{
            $route:function(){

                this.initPro();
            }
        },
        methods: {

            async initPro(){

                 await getProCateList({}).then( res => {
                    
                    this.cateList = res.data
                })

                if(this.id === undefined){
                    
                    this.curId = this.cateList[0].id
                }else{
                    
                    this.curId = this.id
                }

                await getProCateInfo({ id: this.curId }).then( res => {

                    this.proInfo = res.data
                })

                await getProList({ cid: this.curId }).then( res => {
                    
                    if( res.data.length > 0){

                        this.proList = res.data
                        this.parsList = res.data[0].parsList
                        this.filePath = res.data[0].fileInfo
                    }
                    this.$nextTick(() => {

                        const wow = new WOW({
                            boxClass:'wow',
                            animateClass: 'animated',
                            offset: 100,
                            live: true
                        })
                        wow.init();
                        this.$refs.product.scrollIntoView();
                        const _this = this
                        _this.isMobile = _isMobile();
                        if(_this.isMobile){

                            new Swiper('.swiper-pro .swiper-container',{
                                slidesPerView: 1,
                                observer: true,
                                observeParents:true,
                                navigation: {
                                    nextEl: '.swiper-pro .swiper-button-next',
                                    prevEl: '.swiper-pro .swiper-button-prev',
                                },
                                pagination: {
                                    el: '.swiper-pro .swiper-pagination',
                                },
                                on:{
                                    slideChangeTransitionEnd:function(){

                                        _this.parsList = _this.proList[this.realIndex].parsList
                                        _this.filePath = _this.proList[this.realIndex].fileInfo
                                    }
                                }
                            });
                        }else{

                            new Swiper('.swiper-pro .swiper-container',{
                                slidesPerView: 3,
                                centeredSlides: true,
                                initialSlide: 1,
                                loop: true,
                                observer: true,
                                observeParents:true,
                                navigation: {
                                    nextEl: '.swiper-pro .swiper-button-next',
                                    prevEl: '.swiper-pro .swiper-button-prev',
                                },
                                pagination: {
                                    el: '.swiper-pro .swiper-pagination',
                                },
                                on:{
                                    slideChangeTransitionEnd:function(){

                                        _this.parsList = _this.proList[this.realIndex].parsList
                                        _this.filePath = _this.proList[this.realIndex].fileInfo
                                    }
                                }
                            });
                        }
                        new Swiper('.swiper-adv',{
                            slidesPerView: 1,
                            spaceBetween: 10,
                            observer: true,
                            navigation: {
                                nextEl: '.swiper-adv .swiper-button-next',
                                prevEl: '.swiper-adv .swiper-button-prev',
                            },
                            pagination: {
                                el: '.swiper-adv .swiper-pagination',
                            },
                        });
                    })
                })
            },
            goAnchor( num ){

                if( num === 0 ) {

                    this.$refs.adv.scrollIntoView({behavior: "smooth"});
                }else{

                    this.$refs.pro.scrollIntoView({behavior: "smooth"});
                }   
            }
        }
    }
</script>

<style lang="scss" scoped>
.product{
    position: relative;
    .product-banner{
        height: 27.0833vw;
        .nav{
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3.125vw;
            border-top: 1px solid rgba(255,255,255,0.3);
            box-sizing: border-box;
            width: 100%;
            .container{
                display: flex;
            }
            a{
                font-size: 0.9896vw;
                line-height: 3.125vw;
                box-sizing: border-box;
                margin-top: -1px;
                border-top: 1px solid transparent;
                height: 3.125vw;
                color: #fff;
                margin-right: 4.5833vw;
                cursor: pointer;
            }
            .active{
                border-top-color: #E60011;
                font-weight: bold;
            }
        }
    }
    .adv-box{
        padding: 4.1667vw 0;
        background: #F2F4F8;
        .top{
            display: flex;
            justify-content: space-between;
        }
        .info{
            font-size: 1.5625vw;
            line-height: 1.9792vw;
            color: rgba(0, 0, 0, 0.6);
            width: 41.6667vw;
        }
    }
    .adv-main{
        display: flex;
        justify-content: space-between;
        margin-top: 4.4792vw;
        .adv-right{
            width: 40.625vw;
            height: 38.5417vw;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            background: #232323;
            padding: 0 5.7292vw 0 5.4688vw;
        }
        .box{
            .item{
                margin-bottom: 2.0833vw;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .name{
                display: flex;
                align-items: center;
                font-size: 1.25vw;
                line-height: 1.0833;
                color: #fff;
                font-weight: bold;
            }
            .num{
                width: 1.25vw;
                height: 1.25vw;
                background: #E60011;
                border-radius: 50%;
                font-size: 0.8333vw;
                margin-right: 0.5208vw;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .txt{
                padding-left: 1.7708vw;
                margin-top: 0.3125vw;
                font-size: 0.8333vw;
                line-height: 1.625;
                color: #fff;
            }
        }
    }
    .swiper-adv{
        width: 40.625vw;
        height: 38.5417vw;
        padding-bottom: 4.1667vw;
        .swiper-slide{
            position: relative;
            .imgBg{
                height: 38.5417vw;
            }
            .main{
                position: absolute;
                left: 5.2083vw;
                top: 12.5vw;
                width: 21.3542vw;
            }
            .name{
                font-size: 1.7708vw;
                line-height: 1.875vw;
                color: rgba(0, 0, 0, 0.8);
                font-weight: 600;
            }
            .txt{
                font-size: 0.8333vw;
                line-height: 1.25vw;
                color: rgba(0, 0, 0, 0.8);
                margin-top: 1.8229vw;
                font-weight: 300;
            }
        }
        .swiper-button-next,
        .swiper-button-prev{
            left: 0;
            top: auto;
            margin: 0;
            bottom: 0;
            width: 2.6042vw;
            height: 2.6042vw;
            background: #232323;
            &::after{
                display: none;
            }
            i{
                display: block;
                width: 1.25vw;
                height: 1.25vw;
                background: url('../../assets/images/arrowR.png') no-repeat;
                background-size: cover;
                transform: rotate(180deg);
            }
            &:hover{
                background: #E60011;
            }
        }
        .swiper-button-next{
            margin-left: calc(2.6042vw + 2px);
            i{
                transform: rotate(0deg);
            }
        }
        .swiper-pagination{
            display: none;
        }
    }
    .pro-box{
        padding: 4.1667vw 0;
        .top{
            display: flex;
            justify-content: space-between;
        }
        .nav{
            display: flex;
            li{
                margin-right: 4.6875vw;
                font-size: 0.9896vw;
                line-height: 2.5vw;
                color: rgba(0, 0, 0, 0.6);
            }
            .active{
                border-bottom: 2px solid #E60011;
            }
        }
        .swiper-pro{
            margin-top: 4.1667vw;
            position: relative;
            .swiper-slide{
                transform: scale(0.75);
                .name{
                    font-size: 2.0833vw;
                    line-height: 1;
                    color: rgba(0, 0, 0, 0.3);
                    font-weight: 600;
                    text-align: center;
                }
                .img{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 34.0625vw;
                    img{
                        display: block;
                        max-width: 100%;
                        max-height: 34.0625vw;
                    }
                }
            }
            .swiper-slide-active{
                transform: scale(1);
                .name{
                    color: rgba(0, 0, 0, 0.8);
                }
            }
            .swiper-button-next,
            .swiper-button-prev{
                left: -2.6042vw;
                width: 2.6042vw;
                height: 2.6042vw;
                background: #232323;
                &::after{
                    display: none;
                }
                i{
                    display: block;
                    width: 1.25vw;
                    height: 1.25vw;
                    background: url('../../assets/images/arrowR.png') no-repeat;
                    background-size: cover;
                    transform: rotate(180deg);
                }
                &:hover{
                    background: #E60011;
                }
            }
            .swiper-button-next{
                left: auto;
                right: -2.6042vw;
                i{
                    transform: rotate(0deg);
                }
            }
            .swiper-pagination{
                display: none;
            }
        }
        .list{
            display: flex;
            justify-content: center;
            .item{
                margin-right: 1.875vw;
                padding-right: 1.875vw;
                border-right: 1px solid rgba(0, 0, 0, 0.8);
                text-align: center;
            }
            p{
                font-size: 0.9375vw;
                line-height: 1.1458vw;
                color: rgba(0, 0, 0, 0.8);
            }
            .num{
                font-size: 1.25vw;
                line-height: 1.7708vw;
                margin-top: 0.625vw;
                color: rgba(0, 0, 0, 0.8);
                font-weight: 300;
                span{
                    font-size: 1.4583vw;
                    font-weight: bold;
                }
            }
        }
        .read-more{
            margin: 2.8125vw auto 0;
        }
    }
}
@media screen and (max-width: 750px) {
    .product{
        .product-banner{
            height: 6rem;
            .nav{
                height: 1rem;
                a{
                    font-size: .3rem;
                    line-height: 1rem;
                    margin-right: .4rem;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .adv-box{
            padding: .8rem 0;
            .top{
                display: block;
            }
            .info{
                width: 100%;
                font-size: .26rem;
                line-height: .44rem;
            }
        }
        .adv-main{
            margin-top: .5rem;
            display: block;
            .adv-right{
                width: 100%;
                margin-top: .5rem;
                height: auto;
                padding: .6rem .5rem;
                display: block;
            }
            .box{
                .item{
                    margin-bottom: .3rem;
                }
                .name{
                    font-size: .3rem;
                    line-height: 1.5;
                }
                .num{
                    width: .42rem;
                    height: .42rem;
                    margin-right: .1rem;
                    font-size: .2rem;
                }
                .txt{
                    padding-left: .52rem;
                    margin-top: .2rem;
                    font-size: .26rem;
                }
            }
        }
        .swiper-adv{
            width: 100%;
            height: 6.5rem;
            padding-bottom: .5rem;
            .swiper-slide{
                .imgBg{
                    height: 6.5rem;
                }
            }
            .swiper-button-next,
            .swiper-button-prev{
                display: none;
            }
            /deep/.swiper-pagination{
                display: flex;
                justify-content: center;
                bottom: 0;
                .swiper-pagination-bullet{
                    width: .12rem;
                    height: .12rem;
                    margin: 0 .12rem;
                    background: #AFBAC7;
                }
                .swiper-pagination-bullet-active{
                    background: #E60011;
                }
            }
        }
        .pro-box{
            padding: .8rem 0;
            .swiper-pro{
                margin-top: .5rem;
                padding-bottom: .5rem;
                .swiper-slide{
                    .name{
                        font-size: .32rem;
                        line-height: .52rem;
                    }
                    .img{
                        height: 4.2rem;
                        img{
                            max-height: 4.2rem;
                        }
                    }
                }
                .swiper-button-next,
                .swiper-button-prev{
                    display: none;
                }
                /deep/.swiper-pagination{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    bottom: 0;
                    .swiper-pagination-bullet{
                        width: .12rem;
                        height: .12rem;
                        margin: 0 .12rem;
                        background: #AFBAC7;
                    }
                    .swiper-pagination-bullet-active{
                        background: #E60011;
                    }
                }
            }
            .list{
                margin-top: .5rem;
                flex-wrap: wrap;
                justify-content: flex-start;
                .item{
                    text-align: left;
                    padding-right: .36rem;
                    margin-right: .36rem;
                    margin-bottom: .3rem;
                    &:nth-child(3){
                        border-right: none;
                        margin-right: 0;
                        padding-right: 0;
                    }
                    &:last-child{
                        border-right: none;
                    }
                }
                p{
                    font-size: .24rem;
                    line-height: .34rem;
                }
                .num{
                    font-size: .34rem;
                    left: .44rem;
                    margin-top: .1rem;
                    span{
                        font-weight: normal;
                        font-size: .34rem;
                    }
                }
            }
            .read-more{
                margin: .4rem 0 0;
            }
        }
    }
}
</style>